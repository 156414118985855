import { Tab } from "@headlessui/react";
import { PencilSquareIcon, QrCodeIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Popover, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import Badge from "shared/Badge/Badge";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import GuestsInput from "./../../components/HeroSearchForm2Mobile/GuestsInput";
import { EventFormQuestionType, EventPriceDataType, EventSeatType, EventPriceRuleDataType, EventPriceRuleConditionDataType } from "data/types";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useParams, useSearchParams } from "react-router-dom";
//import Select from "shared/Select/Select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Alert from '@mui/material/Alert';
import ButtonCircle from "shared/Button/ButtonCircle";
import { Checkbox } from "@mui/material"
import { eventGoAddToCartAndPay, eventGoFormSubmission, eventGoFreeItemsCheckout } from "hooks/EventGoAPI";
import { auth, analytics, fs, } from "firebaseSetup";
import { logEvent } from "firebase/analytics";
import { onSnapshot, collection, query, where} from 'firebase/firestore'
import Select from "react-select";



export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {

  const {id, viewId} = useParams()
  const [searchParams] = useSearchParams();
  const requestedId = searchParams.get('requestId') || "";

  const [eventPrices, setEventPrices] = useState<EventPriceDataType[]>([])
  const [orders, setOrders] = useState<{[key: string]: EventPriceDataType}>({})
  const [orderTotal, setOrderTotal] = useState<number>(0)
  const [orderQuan, setOrderQuan] = useState<number>(0)
  const [orderLock, setOrderLock] = useState<Boolean>(false)
  const [formQuestionRequiredIds, setFormQuestionRequiredIds] = useState<string[]>([])
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    emailRepeat: '',
    userUID: '',
  })

  const [errorHandler, setErrorHandler] = useState({
    firstName: '',
    lastName: '',
    email: '',

  })

  const [userCoupon, setUserCoupon] = useState({
    coupon: '',
    couponType: '',
    promotionCode: '',
    amount: 0,
    conditions: {},
    cart: {}
  })




  const [seatSelected, setSeatSelected] = useState<string[]>([])
  const [allSeats, setAllSeats] = useState<EventSeatType[]>([])
  const [allSeatsSelected, setAllSeatsSelected] = useState<EventSeatType[]>([])
  const [seatSelectAlert, setSeatSelectAlert] = useState<string>("")
  const [seatByPrices, setSeatByPrices] = useState<{[key: string]: EventSeatType[]}>({})
  const [seatMapURLs, setSeatMapURLs] = useState<string[]>([])

  let [eventDetails, setEventDetails] = useState({
    title: "",
    description: "",
    photoURL: "",
    location: "",
    orgId: "",
    startDateString: "",
    endDateString: "",
    options: {
      seat: false,
      review: false,
    },
    price: 0,
    priceCurrency: 'CAD',
    address: {fullAddress: '', online: false, city: ""},
    status: 'live',
    promotion: {
      count: 0,
      coupon: '',
      percentage: 10,
      type: 'counter',
      promotionCode: '',
    },
  })
  let [formTotalQuestions, setFormTotalQuestions] = useState<EventFormQuestionType[]>([])
  let [formQuestions, setFormQuestions] = useState<EventFormQuestionType[]>([])
  let [success, setSuccess] = useState<string>("")
  let [contactSuccess, setContactSuccess] = useState<string>("")
  let [formSuccess, setFormSuccess] = useState<string>("")
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  let [ticketSold, setTicketSold] = useState<{[key: string]: number}>({})

  //promotion
  async function eventApplyPromotionInformation(totalCount: number){
    if (eventDetails.promotion != null){
      let promotionDetails = eventDetails.promotion
      let promoC = ""
      let promoAmount = 0
      if (promotionDetails.promotionCode != null && promotionDetails.promotionCode != ""){
        promoC = promotionDetails.promotionCode
      }
      if (promotionDetails.percentage != null && promotionDetails.percentage > 0){
        promoAmount = promotionDetails.percentage
      }
      if (promotionDetails.count != null && promotionDetails.count > 0){
        if (totalCount >= promotionDetails.count){
          //apply
          setUserCoupon({
            coupon: "EVENT_PROMOTION",
            couponType: "PERCENTAGE",
            promotionCode: promoC,
            amount: promoAmount,
            conditions: {},
            cart: {}
          })
        }
        else{
          setUserCoupon({
            coupon: "",
            couponType: "",
            promotionCode: '',
            amount: 0,
            conditions: {},
            cart: {}
          })
        }
      }
    }
  }

  async function eventPromoValidation(couponCode:string,ePrices: EventPriceDataType[]){
    const dataBody = `coupon=${couponCode.replaceAll(' ','')}&event_id=${id || ''}`;
    fetch("https://v1eventsapiapplycoupon-650318705789.us-central1.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {
          success: false, 
          couponType: "",
          promotionCode: '',
          couponCode: '',
          conditions: {
            priceId: 0,
          },
          cart: {
            priceId: '',
            quantity: 0
          },
          amount: 0}
        dataS = data
        console.log(dataS)
        if (dataS.success){
          if (dataS.couponType == 'FREE'){
            //FREE Order
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: '',
              amount: 0,
              conditions: {},
              cart: {}
            })
            window.alert('Coupon is now applied: Free Order\n Code: ' + couponCode.toUpperCase())

          }
          else if (dataS.couponType == 'FREEOPT'){
            //FREE Opt Order
            var promoConditions = {priceId: 0}
            if (dataS.conditions != null){
              promoConditions = dataS.conditions
            }
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: '',
              amount: 0,
              conditions: promoConditions,
              cart: {}
            })
            //order lock

            setOrderLock(true)

            window.alert('Coupon is now applied: Conditional Free Order\n Code: ' + couponCode.toUpperCase())

          }
          else if (dataS.couponType == "PERCENTAGE"){
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
              conditions: {},
              cart: {}
            })
            window.alert(`Coupon is now applied: ${dataS.amount}% OFF from your total order`)

            
            // if (totalAmount > 0){
            //   let totalDiscountP = Math.round(totalAmount * (dataS.amount / 100))
            //   setDiscountAmount(totalDiscountP)
            //   setDiscountTotal(totalAmount - totalDiscountP)
            // }
            
            // calculateCartAmount()
          }
          else if (dataS.couponType == "AMOUNT"){
            
            // if (totalAmount > 0 && totalAmount > dataS.amount){
            //   setDiscountAmount(dataS.amount)
            //   setDiscountTotal(totalAmount - dataS.amount)
            // }
            if (dataS.cart != null){
              let newPId = ""
              let quant = 0
              if (dataS.cart.priceId != null){
                newPId = dataS.cart.priceId
              }
              if (dataS.cart.quantity != null){
                quant = dataS.cart.quantity
              }
              let priceItms = ePrices.filter((priceItm) => {
                return priceItm.id == newPId
              })
              let orderItems = orders
              if (Object.keys(orderItems).includes(newPId)){
                if (quant > 0){
                  let orderD = orderItems[newPId]
                  orderD.quantity = quant
                  orderD.priceItemId = newPId
                  orderItems[newPId] = orderD
                }
              }
              else{
                if (priceItms.length > 0 && quant > 0){
                  let orderD = priceItms[0]
                  orderD.quantity = quant
                  orderD.priceItemId = newPId
                  orderItems[newPId] = orderD
                }
              }
              let orderT = 0
              Object.values(orderItems).forEach((itmVal) => {
               orderT += itmVal.price * itmVal.quantity
              })
              setOrders(orderItems)
              setOrderTotal(orderT)
              
            }
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
              conditions: {},
              cart: {}
            })
            window.alert(`Coupon is now applied: $${dataS.amount} OFF from your total order`)
            
          }
          else{
            setUserCoupon({
              coupon: "",
              couponType: "",
              promotionCode: "",
              amount: 0,
              conditions: {},
              cart: {}
            })
            window.alert('Coupon is invalid')
          }
        }
        else{
          setUserCoupon({
            coupon: "",
            couponType: "",
            promotionCode: "",
            amount: 0,
            conditions: {},
            cart: {}
          })
          window.alert('Coupon is invalid')
        }
      })
    })
  }

  

  useEffect(()=>{

    //get id
    const viewLoginRedirect = localStorage.getItem("login_redirect")
    if (viewLoginRedirect != null && viewLoginRedirect != ""){
      localStorage.removeItem('login_redirect')
    }
    const viewD = localStorage.getItem("eventView")
    if (viewD != null && viewD != ""){
      let parseData = JSON.parse((viewD || "{}"))
      let eId = ""
      let vId = ""
      if (parseData.eventId != null){
        eId = parseData.eventId
      }
      if (parseData.viewId != null){
        vId = parseData.viewId
      }
      
      //match
      if (eId == (id || "") && vId == (viewId || "")){
        let couponC = ""
        if (parseData.coupon != null){
          //apply
          couponC = parseData.coupon
        }
        fetchEventDetails(couponC)
      }
      else{
        window.location.assign("/e/"+(id || ""))
      }
    }
    else{
      window.location.assign("/e/"+(id || ""))
    }
  },[])

  function fetchEventSeatDetails(){
    const eventId = id || ""
    let url = `https://v1eventsapiloadallseats-650318705789.us-central1.run.app/?event_id=${id || ''}`
    fetch(url,{
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
        if (data.success){
          if (data.seatMaps != null){
            setSeatMapURLs(data.seatMaps)
          }
          if (data.seatCount != null){
            if (data.seatCount > 0){
              if (data.seats != null){
                setAllSeats(data.seats)
              }
            }
          }
        }
      })
    })
  }

  function fetchEventSeatMapURL() {
    return ""
  }

  function fetchEventDetails(coupon:string){
    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIGetTicketData?event_id="+(id || "")+"&view_id="+(viewId || ""),{
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
        setOrderTotal(0)
        setOrderQuan(0)
        if (data.event != null){
          setEventDetails(data.event)
          //get the seat
          if (data.event.options != null){
            if (data.event.options.seat != null){
              if (data.event.options.seat){
                //has seat option
                fetchEventSeatDetails()
              }
            }
            
          }
        }
        else{
          //no event found
          window.location.replace("/e/"+(id || ""))
        }
        var formQuestionns: EventFormQuestionType[] = []
        if (data.form != null){
          if (data.form.data != null){
            formQuestionns = data.form.data
          }
        }
        setFormTotalQuestions(formQuestionns)
        if (formQuestionns.length > 0){
          const formFilters = formQuestionns.filter((fQues) => {
            if (fQues.priceId == "" || fQues.priceId == null){
              return true
            }
            else {
              return false
            }
          })
          setFormQuestions(formFilters)
        }

        if (requestedId != ""){
          setFormQuestions([])
        }

        if (data.ticketSold != null){
          if (data.ticketSold.tickets != null){
            setTicketSold(data.ticketSold.tickets)
          }
        }
        

        if (data.tickets != null){
          let pricesArray: EventPriceDataType[] = []
          let pricesDisplay: EventPriceDataType[] = []
          pricesArray = Object.values(data.tickets)
          pricesArray = pricesArray.filter((priceItm)=>{
            let startSeconds = priceItm.saleStart._seconds
            let endSeconds = priceItm.saleEnd._seconds
            let startD = new Date(startSeconds * 1000)             
            let endD = new Date(endSeconds * 1000)
            let today = new Date()
            if (endD > today && today > startD){
              return true
            }
            else{
              return false
            }
          })
          pricesArray.forEach((priceItm) => {
            let startSeconds = priceItm.saleStart._seconds
            let endSeconds = priceItm.saleEnd._seconds
            let startD = new Date(startSeconds * 1000)             
            let endD = new Date(endSeconds * 1000)
            let today = new Date()
            let soldoutStatus = false
            if (endD > today && today > startD){
              soldoutStatus = false
            }
            else{
              soldoutStatus = true
            }
            pricesDisplay.push({...priceItm, soldout: soldoutStatus})
          })
          
          pricesDisplay.sort((a,b)=>{
            return a.order - b.order
          })
          setEventPrices(pricesDisplay)

          if (coupon != ""){
            eventPromoValidation(coupon.toUpperCase(),pricesDisplay)
          }

          //setup profile
          if (auth.currentUser != null){
            let userEmail = ""
            let userFN = ""
            let userLN = ""
            userEmail = auth.currentUser.email || ""
            
            if (userEmail != ""){
              let usr = localStorage.getItem('user') || "{}"
              if (usr == '{}'){}
              else{
                let userObj = JSON.parse(usr)
                if (userObj.user != null){
                  if (userObj.user.firstName != null){
                    userFN = userObj.user.firstName
                  }
                  if (userObj.user.lastName != null){
                    userLN = userObj.user.lastName
                  }
                }
              }
              setUserInfo({
                ...userInfo,
                emailAddress: userEmail,
                emailRepeat: userEmail,
                firstName: userFN,
                lastName: userLN,
              })
            }
          }

        }
      })
    })
  }

  async function confirmAndPay(){
    logEvent(analytics,"begin_checkout")
    setPaymentLoading(true)
    //calculate total
    if (
      userInfo.firstName != "" && 
      userInfo.lastName != "" && 
      userInfo.emailAddress != "" && 
      userInfo.emailAddress == userInfo.emailRepeat && 
      userInfo.emailAddress.includes('@') && 
      userInfo.emailAddress.includes('.')){


      console.log('information correct: ' + userInfo)
      //check to see if need to submit
    let formSubmissionId = ""
    let formSubmissionItem: any[] = []
    let formSubmissionRequiredFormTitles: string[] = []
    let formSubmissionRequiredFormIds: string[] = []
    let formSubmissionFilledAll = true
    formQuestions.forEach((formItm)=>{
      //if one question that is required and its not filled yet, dont submit
      let formValue = ""
      if (formItm.value != null){
        if (formItm.value != ""){
          formValue = formItm.value
        }
      }
      if (formItm.required && formValue == ""){
        formSubmissionRequiredFormTitles.push(formItm.title)
        formSubmissionRequiredFormIds.push(formItm.id)

        formSubmissionFilledAll = false
      }
      formSubmissionItem.push({
        id: formItm.id,
        title: formItm.title,
        value: formValue,
      })
    })

    setFormQuestionRequiredIds(formSubmissionRequiredFormIds)

    //console.log(formQuestions)
    if (formQuestions.length == 0){
      formSubmissionFilledAll = true
    }
    //
    if (formSubmissionFilledAll){
      const sId = await eventGoFormSubmission(
        id || '',
        userInfo.emailAddress,
        userInfo.firstName,
        userInfo.lastName,
        formSubmissionItem,
      )
      formSubmissionId = sId

      //continue add to cart
      //check shopping cart
    let cartItems: any[] = []
    let reCalculatedTotal = 0
    Object.values(orders).forEach((orderItm) => {
      if (orderItm.quantity > 0){
        const subT = Math.round(orderItm.price * orderItm.quantity * 100) / 100
        reCalculatedTotal += subT
        cartItems.push({...orderItm, cartItemId: orderItm.id})
      }
    })

    const eveId = id || ''
    const uniqueId = viewId || ''

    let couponC = userCoupon.coupon
    let promotionC = userCoupon.promotionCode
    let couponT = userCoupon.couponType

    if (userCoupon.couponType == 'AMOUNT' && userCoupon.amount >= reCalculatedTotal){
      couponT = "FREE"
    }

    var couponValidated = true
    if (couponC != ""){
      if (couponC == 'EVENT_PROMOTION'){
        //Event promotion is only in frontend
        couponValidated = true
      }
      else{
        const couponV = await requireCouponValidation(couponC,userInfo.emailAddress)
        couponValidated = couponV
      }
    }

    var seatPass = false
    var seatValidationPass = false
    let seatIds: string[] = []

    var notMatchingPriceIds: string[] = []
    
    allSeatsSelected.forEach((seatItm) => {
      seatIds.push(seatItm.id)
    })
    if (eventDetails.options.seat != null){
      if (eventDetails.options.seat && seatIds.length > 0 && seatIds.length == orderQuan) {
        seatPass = true
        //make sure selected seats are in place
        let passObj = await requireSeatValidation()
        seatPass = passObj.allPass
        seatValidationPass = passObj.validationPass
        notMatchingPriceIds = passObj.priceIds
      }
      else{
        seatPass = false
      }

      if (!seatValidationPass && eventDetails.options.seat){
        //triggle refresh
        //seat taken
        window.location.reload()
      }
      
    }
    else{
      //not required to select seat
      seatPass = true
    }

    
    

    if (eveId != '' && uniqueId != '' && cartItems.length > 0 && couponValidated && seatPass){
      //add to cart and pay
      couponC = couponC.replaceAll(" ","")

      eventGoAddToCartAndPay(
        eveId,
        uniqueId,
        userInfo.firstName,
        userInfo.lastName,
        userInfo.emailAddress.toLowerCase(),
        eventDetails.title,
        eventDetails.photoURL,
        eventDetails.orgId,
        couponC,
        promotionC,
        couponT,
        formSubmissionId,
        cartItems,
        seatIds,
        allSeatsSelected)
    }
    else{
      if (!couponValidated){
        //coupon redeemabled
        setPaymentLoading(false)
        alert('You have ready redeemed the coupon using same email')
        setSuccess("You have ready redeemed the coupon using same email")
      }
      else{
        setPaymentLoading(false)
        alert('Please add items to the cart before you continue')
        setSuccess("No items in cart")
      }
      if (!seatPass){
        let seatMessage = `Please check the following seat selection: `
        notMatchingPriceIds.forEach((priceId) => {
          if (Object.keys(orders).includes(priceId)){
            let priceName = orders[priceId].priceDescription
            seatMessage += priceName + ', '
          }
        })
        setPaymentLoading(false)
        alert('Please select seat to continue')
        setSuccess(seatMessage)
      }
      
    }
    
    }
    else{
      setPaymentLoading(false)
      let requiredWords = `Please fill all required questions: \n\n`
      formSubmissionRequiredFormTitles.forEach((qTitle, index)=>{
        requiredWords += `${index+1}. ${qTitle}\n`
      })
      alert(requiredWords)
      setFormSuccess(requiredWords)
    }

    }
    else{
      //information not correct
      setPaymentLoading(false)
      
      if (userInfo.firstName == "" || userInfo.lastName == ""){
        alert("You have to fill your name")
        setContactSuccess("You have to fill your name")
      }
      if (userInfo.emailAddress != userInfo.emailRepeat){
        alert("Your emails need to be match")
        setContactSuccess("Your emails need to be match")
      }
      if (userInfo.emailAddress == ""){
        alert("You have to fill your email")
        setContactSuccess("You have to fill your email")
      }
    }
    
  }

  async function requireSeatValidation(){
    let remainingSeats: EventSeatType[] = []
    let url = `https://v1eventsapiloadallseats-650318705789.us-central1.run.app/?event_id=${id || ''}`
    const firstResp = await fetch(url,{headers: {"Content-Type": "application/json",}})
    const respData = await firstResp.json()
    if (respData.success){
      if (respData.seatCount != null){
        if (respData.seatCount > 0){
          if (respData.seats != null){
            remainingSeats = respData.seats
          }
        }
      }
    }
    //check if current seat selections are in
    var allPass = true
    var validationPass = true
    let notMatchedPriceIds: string[] = []
    //make sure all seats are not taken
    allSeatsSelected.forEach((seatItm) => {
      const itemExist = remainingSeats.filter((seatI) => { return seatI.id == seatItm.id }).length > 0
      if (!itemExist){
        allPass = false
        validationPass = false
      }
    })
    //make sure selected seats match quantity
    Object.keys(orders).forEach((priceId)=>{
      let orderQuan = orders[priceId].quantity
      let seatsData = allSeatsSelected.filter((seatItm) => {return seatItm.priceId == priceId})
      let seatsQuan = seatsData.length
      if (orderQuan != seatsQuan){
        allPass = false
        notMatchedPriceIds.push(priceId)
      }
    })
    
    return {allPass: allPass, validationPass: validationPass, priceIds: notMatchedPriceIds}
  }

  function shoppingCartValidation(){
    let allConditions: boolean[] = []
    Object.keys(orders).forEach((priceId) => {
      if (orders[priceId].priceRules != null){
        let priceItm = orders[priceId]
        priceItm.priceRules.map((ruleItm)=>{
          if (ruleItm.conditions != null){
            ruleItm.conditions.map((conditionItm) => {
              if (conditionItm.destination == 'cart' && conditionItm.operator == 'IN'){
                let priceFieldId = conditionItm.field_id
                if (priceFieldId != null && priceFieldId != "" && conditionItm.number != null){
                    if (Object.keys(orders).includes(priceFieldId) && orders[priceFieldId].quantity >= conditionItm.number){
                      allConditions.push(true)
                    }
                    else{
                      allConditions.push(false)
                    }
                  }
                }
              })
            }
          })

      }
    })
    return allConditions
  }

  async function requireCouponValidation(couponCode:string,email:string){
    var validad = true
    const dataBody = `coupon=${couponCode.replaceAll(' ','')}&event_id=${id || ''}&email=${email}`;
    try {
      const fetchResponse = await fetch("https://v1eventsapiapplycoupon-650318705789.us-central1.run.app?"+dataBody,{
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', }
      })
      const data = await fetchResponse.json();
      var dataS = {
        success: false, 
        couponType: "",
        promotionCode: '',
        couponCode: '',
        conditions: {
          priceId: 0,
        },
        amount: 0}
      dataS = data
      validad = dataS.success
  } catch (e) {
    validad = false
      //return e;
      
  }
  return validad    
    
  }

  function loadFormsBasedOrders(){
    let priceIds: string[] = []
    Object.keys(orders).forEach((priceId) => {
      if (!priceIds.includes(priceId)){
        priceIds.push(priceId)
      }
    })
    if (formTotalQuestions.length > 0){
      const formFilters = formTotalQuestions.filter((fQues) => {
        if (fQues.priceId == "" || fQues.priceId == null){
          return true
        }
        else if (priceIds.includes(fQues.priceId)){
          return true
        }
        else {
          return false
        }
      })
      setFormQuestions(formFilters)
    }
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={eventDetails.photoURL}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {eventDetails.location}
              </span>
              <span className="text-base font-medium mt-1 block">
                {eventDetails.title}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {eventDetails.startDateString}
            </span>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {eventDetails.address.online ? "Online Event" : eventDetails.address.fullAddress}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Order Summary</h3>
          {
            Object.values(orders).filter((orderItm) => {
              return orderItm.quantity > 0
            }).map((orderItm) => {
              let seatSelected = false
              let selectedSeatss = (allSeatsSelected.filter((seatItm) => seatItm.priceId == orderItm.id))
              seatSelected = selectedSeatss.length > 0
              let seatsString = ""
              return <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <div>
              <span>{orderItm.priceDescription} X {orderItm.quantity}</span>
              {seatSelected && <div><br/><span>Seats: <b>{seatsString}</b></span></div>}
              </div>
              <span>{(Math.round(orderItm.price * orderItm.quantity * 100) / 100).toLocaleString('en-US',{style: 'currency', currency: orderItm.priceCurrency})}</span>
            </div>
            })
          }
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {userCoupon.coupon != "" &&
          <div>
          <div className="flex justify-between font-semibold">
          <span>Coupon Applied</span>
          <span>{userCoupon.coupon}</span>
          </div>
          <div className="flex justify-between font-semibold">
          <span>Coupon Type</span>
          <span>{(userCoupon.couponType == "FREE" || userCoupon.couponType == "FREEOPT") ? "Free Order" : userCoupon.couponType == "AMOUNT" ? "Discount Amount " + userCoupon.amount.toLocaleString('en-US',{style: 'currency', currency: eventDetails.priceCurrency}) : "Discount Percentage " + userCoupon.amount + "%" }</span>
          </div>
        </div>
        }
          <div className="flex justify-between font-semibold">
            <span>Sub-Total</span>
            <span>{orderTotal.toLocaleString("en-US",{style: 'currency', currency: eventDetails.priceCurrency})}</span>
          </div>
        </div>
      </div>
    );
  };

  const generateRandomCode = () => {
    var sValidCharacters = "0123456789";
    var sCharCode = "";
    for (let i = 0; i < 5; i++) {
      const aNumber = Math.random() * sValidCharacters.length
        sCharCode = sCharCode + sValidCharacters.charAt(aNumber);
    }
    return sCharCode
  }

  const [couponApplied, setCouponApplied] = useState('')
  const renderCouponButton = () => {
    return (
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
      <div className="mt-10 mb-5 relative max-w-sm">
      <form>
        <Input
          required
          aria-required
          placeholder="Enter coupon code"
          type="text"
          rounded="rounded-full"
          value={couponApplied}
          onChange={(e)=>{
            let coupon = e.target.value
            setCouponApplied(coupon.toUpperCase())
          }}
        />
      </form>
      {/* <ButtonCircle
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
            onClick={()=>{
              if (couponApplied != ""){
                eventPromoValidation(couponApplied.toUpperCase())
              }
            }}
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle> */}
      </div>
      <ButtonSecondary className="mb-5" onClick={()=>{
        if (couponApplied != ""){
          eventPromoValidation(couponApplied.toUpperCase(),eventPrices)
        }
      }}>
        Apply Coupon
      </ButtonSecondary>
      </div>
    )
  }

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Event Confirmation
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <ButtonSecondary className="mb-4" href={"/e/"+(id || "")}>{"← Back to Event"}</ButtonSecondary>
            <h3 className="text-2xl font-semibold">Your Event</h3>
            <h3 className="mt-1 text-xl font-semibold">{eventDetails.title}</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View event details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Event details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <button
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {eventDetails.startDateString}
                    </span>
                  </div>
                  {/* <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" /> */}
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Location</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {eventDetails.address.online ? "Online" : eventDetails.location}
                      </span>
                    </span>
                  </div>
                  {/* <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" /> */}
                </button>
              )}
            />

          </div>
        </div>

        {eventDetails.options.seat && seatMapURLs.length > 0 && <div>
        <h3 className="text-2xl font-semibold">Seat Map</h3>
        {seatMapURLs.map((seatMapURL)=>{
          return <img
          src={seatMapURL}
          alt={seatMapURL}
          style={{objectPosition: 'top', objectFit: 'cover'}}
        />
        })}
        
        </div>}

        <div>
        <h3 className="text-2xl font-semibold">Select Tickets</h3>
        {success && <Alert severity="success">
          {success}
        </Alert>}
        { eventPrices?.map((priceItm) => {

          let pricingDetails: string[] = []
          if (priceItm.priceDescriptions != null){
            pricingDetails = priceItm.priceDescriptions
          }
          let quantityIncrease: number = 1
          if (priceItm.increase != null){
            quantityIncrease = priceItm.increase || 1
          }
          let currentVal = 0
          if (Object.keys(orders).includes(priceItm.id)){
            let currentItm = orders[priceItm.id]
            if (currentItm.quantity != null){
              currentVal = currentItm.quantity
            }
          }
          //check current rule
          let soldout = false
          if (priceItm.soldout != null){
            soldout = priceItm.soldout
          }
          if (priceItm.priceRules != null){
            priceItm.priceRules.map((ruleItm)=>{
              if (ruleItm.conditions != null){
                ruleItm.conditions.map((conditionItm) => {
                  if (conditionItm.destination == 'cart' && conditionItm.operator == 'IN'){
                    let priceFieldId = conditionItm.field_id
                    if (priceFieldId != null && priceFieldId != "" && conditionItm.number != null){
                      if (Object.keys(orders).includes(priceFieldId) && orders[priceFieldId].quantity >= conditionItm.number){
                        soldout = false
                      }
                      else{
                        soldout = true
                      }
                    }
                  }
                })
              }
            })
          }

          //ticket sold
          let remainingTicket = 500
          if (priceItm.count != null){
            let totalT = 500
            totalT = priceItm.count
            if (Object.keys(ticketSold).includes(priceItm.id)){
              let ticketSoldNumber = ticketSold[priceItm.id]
              remainingTicket = totalT - ticketSoldNumber
            }
            else{
              remainingTicket = totalT
            }
          }
          else{

          }

          let priceMaxTicket = priceItm.max
          if (remainingTicket < priceItm.max){
            priceMaxTicket = remainingTicket
          }

          if (remainingTicket <= 0){
            soldout = true
          }

          

          return <div><NcInputNumber
          className="w-full mt-6"
          orderMin={priceItm.min}
          defaultValue={currentVal}
          increase={quantityIncrease}
          max={priceMaxTicket}
          min={0}
          soldout={soldout}
          label={priceItm.price.toLocaleString('en-US',{style: 'currency', currency: priceItm.priceCurrency})}
          desc={priceItm.priceDescription + " (Tax Included)"}
          onChange={(value) => {
            let orderItems = orders
            if (Object.keys(orderItems).includes(priceItm.id)){
              let orderD = orderItems[priceItm.id]
              if (value >= orderD.min && value > 0){
                orderD.quantity = value
                orderD.priceItemId = priceItm.id
                orderItems[priceItm.id] = orderD
              }
              else{
                delete orderItems[priceItm.id]
              }
              
              console.log(orderItems)
            }
            else{
              let orderD = priceItm
              if (value >= orderD.min && value > 0){
                orderD.quantity = value
                orderD.priceItemId = priceItm.id
                orderItems[priceItm.id] = orderD
              }
              else{

              }
              
            }
            let orderT = 0
            let orderNum = 0
            Object.values(orderItems).forEach((itmVal) => {
              orderT += itmVal.price * itmVal.quantity
              orderNum += itmVal.quantity
            })
            setOrders(orderItems)
            setOrderTotal(orderT)
            setOrderQuan(orderNum)

            if (orderNum > 0){
              //
              eventApplyPromotionInformation(orderNum)
            }

            loadFormsBasedOrders()
            
            if (value == 0){
              //removed cart
              setSuccess(`${priceItm.priceDescription} removed from cart`)
            }
            else{
              //added to cart
              //check the form questions
              if (value >= priceItm.min && value > 0){
                setSuccess(`${priceItm.priceDescription} X ${priceItm.quantity} added to cart`)
              }
            }
          }}
          
        />
        {priceItm.min > 1 && 
        <div className={priceItm.quantity < priceItm.min ? "text-sm text-red-500" : "text-sm"}>
          Minimum order of <b>{priceItm.min}</b>
        </div>
        }

        {remainingTicket <= 30 && 
        <div className={"text-sm text-red-500"}>
          Only <b>{remainingTicket}</b> tickets left
        </div>
        }

      {priceItm.soldout && 
        <div className="mt-2">
          <Badge color="red" name="SOLD OUT"/>
        </div>
      }
        
        <br/>

        {
          eventDetails.options.seat != null && 
          eventDetails.options.seat && 
          Object.keys(orders).includes(priceItm.id) && 

          <div>
            <div className="mt-1 mb-1.5 text-sm font-bold">Select <b>{priceItm.quantity}</b> seats for {priceItm.priceDescription}</div>
            {priceItm.quantity < allSeatsSelected.filter((seatItm) => {return seatItm.priceId == priceItm.id}).length &&
            <div className="mt-1 mb-1.5 text-sm font-bold text-red-500">Please remove your seats to match</div>
            }
            {priceItm.quantity > allSeatsSelected.filter((seatItm) => {return seatItm.priceId == priceItm.id}).length &&
            <div className="mt-1 mb-1.5 text-sm font-bold text-red-500">Please add more seats to continue</div>
            }
          <div
        className={`grid gap-3 md:gap-4 grid-cols-4`}
      >
        {/* sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 */}
        {
          allSeats.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).map((seatItm) => {
            return seatSelected.includes(seatItm.id) ? <ButtonPrimary onClick={()=>{
              if (seatSelected.includes(seatItm.id)){
                //remove it
                let seatIds = seatSelected
                let sSeats = allSeatsSelected
                const index = seatIds.findIndex((itm)=>{
                  return itm == seatItm.id
                })
                seatIds.splice(index, 1)
                setSeatSelected(seatIds)
                
                const indexSeat = sSeats.findIndex((itm)=>{
                  return itm.id == seatItm.id
                })
                sSeats.splice(indexSeat, 1)
                setAllSeatsSelected(sSeats)

                let seatRemaining = priceItm.quantity - sSeats.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).length
                setSuccess(`You have ${seatRemaining} seats to select for ${priceItm.priceDescription} ${generateRandomCode()}`)

              }
            }}>{seatItm.name}</ButtonPrimary> : <ButtonSecondary onClick={()=>{
              //adding it
              let seatIds = seatSelected
              let sSeats = allSeatsSelected
              let seatRemaining = priceItm.quantity - sSeats.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).length

              if (!seatSelected.includes(seatItm.id) && seatRemaining > 0){
                
                seatIds.push(seatItm.id)
                setSeatSelected(seatIds)
                sSeats.push(seatItm)
                setAllSeatsSelected(sSeats)

                setSuccess(`You have ${seatRemaining} seats to select for ${priceItm.priceDescription} ${generateRandomCode()}`)
              }
            }}>{seatItm.name}</ButtonSecondary>
          })
        }

      </div>
      </div>

        }
        

        

        {/* {
        eventDetails.options.seat != null && 
        eventDetails.options.seat && 
        Object.keys(orders).includes(priceItm.id) && 
        <div>
        <div className="mt-1.5 text-sm font-bold">Select <b>{priceItm.quantity}</b> seats for {priceItm.priceDescription}</div>
                <Select
                className="mt-1"
          name="Select your seats"
          placeholder="Select your seats"
          options={allSeats.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).map(function (seatItem) { return {label: seatItem.name, value: seatItem.id}; })}
          onChange={(option) => {
            let sSeats = allSeatsSelected
            let seatIds = seatSelected

            if (option == null){
              return
            }

            let indexes: number[] = []
            indexes = sSeats.map((seatItm, index) => (seatItm.priceId === priceItm.id ? index : -1)).filter((index) => index !== -1);
            console.log('indexes: ' + indexes.length)
            indexes.forEach((index) => {
              sSeats.splice(index, 1);
            })


            if (option.length == 0){

              
              
              let newSIds: string[] = []
              sSeats.map((seatItm, index) => { newSIds.push(seatItm.id) })
              seatIds = newSIds
              setAllSeatsSelected(sSeats)
              setSeatSelected(seatIds)
              let seatRemaining = priceItm.quantity - sSeats.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).length
              setSeatSelectAlert(`You have ${seatRemaining} seats to select for ${priceItm.priceDescription} ${generateRandomCode()}`)
              
            }
            else {
            
            
            //adding current items

            
            
            option.forEach((optItm)=>{
              let optItmVal = allSeats.filter((seatItm) => { return seatItm.id == optItm.value})
              if (optItmVal.length > 0){
                let seatItm = optItmVal[0]
                let seatId = seatItm.id
                if (!seatIds.includes(seatId)){
                  seatIds.push(seatId)
                  sSeats.push(optItmVal[0])
                }
                
              }
            })
            

            setAllSeatsSelected(sSeats)
            setSeatSelected(seatIds)

            let seatRemaining = priceItm.quantity - sSeats.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).length
            setSuccess(`You have ${seatRemaining} seats to select for ${priceItm.priceDescription} ${generateRandomCode()}`)

            }

            
          }}
          isOptionDisabled={() => allSeatsSelected.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).length >= priceItm.quantity}
          isMulti
        />
        {priceItm.quantity - allSeatsSelected.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).length > 0 &&
        <div className={"text-red-500 text-sm font-normal"}>You have <b>{priceItm.quantity - allSeatsSelected.filter((seatItm)=>{return seatItm.priceId == priceItm.id}).length}</b> seats to select</div>
        }
        </div>} */}

        {pricingDetails.length > 0 && <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-80"}
                group px-0 py-0 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
               <InformationCircleIcon className="w-5 h-5 opacity-80" />
              <span className="ml-2 select-none">View Details</span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-50 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-50 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 bg-white w-screen max-w-[350px] px-1.5 mt-4 right-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                    <div style={{margin: '25px'}}>
                        <div className="flex justify-center">
                            <span className="text-2xl font-semibold text-center text-black">{priceItm.priceDescription}</span>
                        </div>
                        <div className="mt-2 text-black">
                        { pricingDetails.map((itm) => {
          if (itm.includes('*** ')){
            const itmCount = itm.split('*** ')
            if (itmCount.length == 3){
              //highlight word
              return <div>
                <span className="font-normal text-black">
              {itmCount[0]}
              </span>
              <span className="font-normal text-black">
              <b>{itmCount[1]}</b>
              </span>
              <span className="font-normal text-black">
              {itmCount[2]}
              </span>
              <br />
              </div>
            }
            else{
              //highlight line
              return <div>
          <span className="font-normal text-black">
          <b>{itm.replaceAll('*** ','')}</b>
          </span>
          <br />
          </div>
            }
          }
          else{
            return <div>
          <span className="font-normal text-black">
          {itm}
          </span>
          <br />
          </div>
          }
          
        })}
        </div>
                        
                    </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover> }
       
        
        </div>
        })}
          
          </div>
          

        <div className="mt-5">
        {contactSuccess && <Alert severity="success">
          {contactSuccess}
        </Alert>}
          <h3 className="text-2xl font-semibold">Contact Information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            
            <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1 mt-2">
                      <Label>First Name *</Label>
                      <Input type="text" defaultValue={userInfo.firstName} onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.firstName = fn
                        setUserInfo(uInfo)
                      }} />
                    </div>
                    <div className="flex-1 space-y-1 mt-2">
                      <Label>Last Name *</Label>
                      <Input type="text" defaultValue={userInfo.lastName} onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.lastName = fn
                        setUserInfo(uInfo)
                      }} />
                    </div>
                  </div>
                  <div className="space-y-1 mt-2">
                    <Label>Email Address *</Label>
                    <Input 
                      type="email" 
                      defaultValue={userInfo.emailAddress} 
                      onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.emailAddress = fn
                        setUserInfo(uInfo)
                      }}/>
                  </div>
                  <div className="space-y-1 mt-2">
                    <Label>Confirm Email Address *</Label>
                    <Input 
                      type="email" 
                      defaultValue={userInfo.emailAddress} 
                      onChange={(e) => {
                        const fn = e.currentTarget.value
                        let uInfo = userInfo
                        uInfo.emailRepeat = fn
                        setUserInfo(uInfo)
                      }}/>
                  </div>
                  <div className="space-y-1 mt-3">
                    <h3 className="text-2xl font-semibold" hidden={formQuestions.length == 0}>Additional Questions</h3>
                    {formSuccess && <Alert severity="success">
                      {formSuccess}
                    </Alert>}
                  </div>

                  <div className="mt-6">
                  {
                    formQuestions.map((ques,index) => {
                      let labelTextStyle = "text-black"
                      if (formQuestionRequiredIds.includes(ques.id)){
                        labelTextStyle = "text-color-500"
                      }
                      else{
                        labelTextStyle = ""
                      }
                      let multipleSelect = false
                      if (ques.allowMultiple != null && ques.allowMultiple){
                        multipleSelect = true
                      }
                      else{
                        multipleSelect = false
                      }

                      if (ques.optionType == "options"){
                        //select
                        if (multipleSelect){
                          //select multiple options
                          return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Select
                          name={ques.title}
                          placeholder={ques.placeholder}
                          options={ques.options.map(function (optionItm) { return {label: optionItm, value: optionItm}; })}
                          onChange={(option) => {
                            if (option === null) {
                              return;
                            }
              
                            let allValues: string[] = []
                            option.forEach((optItm)=>{
                              allValues.push(optItm.value)
                            })
                            
                            let allQues = formQuestions
                            let cQues = ques
                            if (allValues.length == 0){
                              cQues.value = ""
                            }
                            else{
                              cQues.value = allValues.join(" =+= ")
                            }
                            allQues[index] = cQues
                            setFormQuestions(allQues)

                          }}
                          isMulti={multipleSelect}/>
                          </div>
                        }
                        else{
                          return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Select
                          name={ques.title}
                          placeholder={ques.placeholder}
                          options={ques.options.map(function (optionItm) { return {label: optionItm, value: optionItm}; })}
                          onChange={(option) => {
                            if (option === null) {
                              return;
                            }
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = option.value || ""
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}/>
                          </div>
                        }
                        
                          
                      }
                      else if (ques.optionType == "text"){
                        if (ques.valueType == "phone"){
                          return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <PhoneInput
                                  placeholder={ques.title}
                                  value={""}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    let allQues = formQuestions
                                    let cQues = ques
                                    cQues.value = e || ""
                                    allQues[index] = cQues
                                    setFormQuestions(allQues)
                                  }}/>
                          </div>
                        }
                        else{
                          return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type={'text'} defaultValue="" onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}/>
                          </div>
                        }
                        
                      }
                      else if (ques.optionType == "file"){
                        return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Input type="file" defaultValue="" />
                          </div>
                      }
                      else if (ques.optionType == "textarea"){
                        return <div className="space-y-1 mt-2">
                          <Label className={labelTextStyle}>{index+1}. {ques.title} {ques.required ? '*' : ''}</Label>
                          <Textarea onChange={(e)=>{
                            let allQues = formQuestions
                            let cQues = ques
                            cQues.value = e.currentTarget.value
                            allQues[index] = cQues
                            setFormQuestions(allQues)
                          }}
                          />
                          </div>
                      }
                    })
                  }
                  </div>
            
            <div className="pt-8">

            <h3 className="text-2xl font-semibold mt-3">Have Coupon Code?</h3>
              {renderCouponButton()}

        {userCoupon.coupon != "" &&
          <div className="mt-8">
          <div className="flex justify-between font-semibold">
          <span>Coupon Applied</span>
          <span>{userCoupon.coupon}</span>
          </div>
          <div className="flex justify-between font-semibold">
          <span>Coupon Type</span>
          <span className="text-red-500">{(userCoupon.couponType == "FREE" || userCoupon.couponType == "FREEOPT") ? "Free Order" : userCoupon.couponType == "AMOUNT" ? "Discount Amount " + userCoupon.amount.toLocaleString('en-US',{style: 'currency', currency: eventDetails.priceCurrency}) : "Discount Percentage " + userCoupon.amount + "%" }</span>
          </div>
        </div>
        }
        <div className="flex flex-col space-y-4">
        <h3 className="text-2xl font-semibold mt-3">Order Summary</h3>
          {
            Object.values(orders).filter((orderItm) => {
              return orderItm.quantity > 0
            }).map((orderItm) => {
              let seatSelected = false
              let selectedSeatss = (allSeatsSelected.filter((seatItm) => seatItm.priceId == orderItm.id))
              seatSelected = selectedSeatss.length > 0
              let seatsString = ""
              selectedSeatss.map((sItm) => {seatsString += sItm.name + ", "})
              return <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <div>
              <span>{orderItm.priceDescription} X {orderItm.quantity}</span>
              {seatSelected && <div><br/><span>Seats: <b>{seatsString}</b></span></div>}
              </div>
              <span>{(Math.round(orderItm.price * orderItm.quantity * 100) / 100).toLocaleString('en-US',{style: 'currency', currency: orderItm.priceCurrency})}</span>
            </div>
            })
          }
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between font-semibold mt-3">
            <span>Sub-Total</span>
            <span>{orderTotal.toLocaleString("en-US",{style: 'currency', currency: eventDetails.priceCurrency})}</span>
        </div>
        {userCoupon.coupon != "" && <div className="flex justify-between font-semibold mt-3">
            <span>After Discount</span>
            <span>{userCoupon.couponType == 'FREE' ? 'FREE' : userCoupon.couponType == "AMOUNT" ? (orderTotal > userCoupon.amount ? orderTotal - userCoupon.amount : 0).toLocaleString("en-US",{style: 'currency', currency: eventDetails.priceCurrency}) : userCoupon.couponType == "PERCENTAGE" ? (orderTotal - Math.round(orderTotal * (userCoupon.amount / 100) * 100) / 100).toLocaleString('en-US',{style: 'currency', currency: eventDetails.priceCurrency}) : ''}</span>
        </div>}

        </div>

        <label className="block mt-3">
              <Checkbox className="text-neutral-800 dark:text-neutral-200" checked={true} defaultChecked={true}/>              
            <a target="_blank" href="/user-privacy.html">
              <span className="text-neutral-800 dark:text-neutral-200">agree to EventGo Privacy Policy</span>
            </a>    
            </label>
        
              <ButtonPrimary 
                className="mt-3"
                loading={paymentLoading}
                disabled={paymentLoading}
                onClick={()=>{
                //validate shopping cart
                let shoppingCartInvalid = shoppingCartValidation().includes(false)
                if (!paymentLoading && !shoppingCartInvalid){
                  confirmAndPay()
                }
                else{
                  if (shoppingCartInvalid){
                    alert("Please check the rules and conditions for your cart item")
                  }
                }
              }}>Confirm and pay</ButtonPrimary>

          
          

            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
